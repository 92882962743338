<template>
  <span>{{ animatedNumber }}</span>
</template>

<script>
import { gsap } from "gsap";


export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    animateOnLoad: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      tweenedNumber: 0,
    }
  },
  computed: {
    animatedNumber: function() {
      return this.tweenedNumber.toFixed(0);
    }
  },
  watch: {
    value: function(newValue) {
      this.tween(newValue, 5)
    }
  },
  mounted: function () {
    if (this.animateOnLoad) {
      this.tween(this.value, 2)
    } else {
      this.tweenedNumber = this.value
    }
  },
  methods: {
    tween(newValue, duration) {
      if (!duration) {
        duration = 1
      }
      gsap.to(this.$data, { duration: duration, tweenedNumber: newValue });
    },
  }
}
</script>
